.gen-loader {
    margin: 1.5em 0;
    position: relative;
}

.gen-box-background {
    position: absolute;
    z-index: 0;
    background: rgba(246, 129, 129, 1);
    height: 100%;
    top: 0;
    left: 0;
    min-width: 100px;
    margin: 0;
}

.gen-box {
    z-index: 1;
    border: 3px solid #3E3546;
    border-right: 0;
    text-align: center;
    font-size: 2em;
    letter-spacing: 0;
    color: #91DB69;
    background: rgba(246, 129, 129, 0.75);
    opacity: 1;
    margin: 0;
    vertical-align: center;
    padding: 1em 0;
}
.gen-box .eon{
    font-size: 0.5em;
    color: #3E3546;
    display: block;
    margin-top: 1.5em;
}
.gen-loader > div.gen-box:last-child {
    border-right: 3px solid #3E3546;
}