.minting-row{
    color: #8fd3ff;
    font-size: 1.5em;
    vertical-align: middle;
}
.amount-toggle{
    border: 0 none transparent;
    background: none;
    line-height: 0.75em;
    color: #8fd3ff;
    top: -0.3em;
    position: relative;
}
.mint-cost-wrapper{
    display: inline-block;
    color: #91DB69;
    text-align: center;
    vertical-align: middle;
    padding-left: 15px;
}
.mint-cost{
    display: block;
}
.btn.min-stake{
    font-size: 0.75em;
}