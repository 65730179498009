:root{
    --staking-bg: #fbb954;
}

.staking-images-row{
    background-color: var(--staking-bg);
    clear: both;
    margin: 0 15px 15px 15px;
    padding: 5px 15px 10px 15px;
}
.staking-images-row h3{
    text-align: center;
    font-size: 1em ;
    color: #3e3546;
}
.staking-asset{
    width: 50px;
    height: 50px;
}
.image-box{
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    float: left;
}
.image-box .eon{
    position: absolute;
    background: rgba(0,0,0,0.5);
    bottom: 0;
    left: 0;
    text-align: justify;
    font-size: 0.5em;
    width: 100%;
    padding: 0.5em;
}