.avatar-container{
    /*border: 6px solid #F68181;*/
    padding: 0;
    margin-top: -3em;
    z-index: 2;
}
.avatar-image{
    border: 6px solid #F68181;
}
.interactions-container{
    margin-top: -3em;
    padding-left: 7em;
}
.interactions-container .minting-container{
    margin-left: -7em;
}
.interactions-container .staking-container{
    margin-left: 7em;
}

@media only screen and (max-width: 600px) {
    .interactions-container{
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }

    .interactions-container .staking-container,.interactions-container .staking-container{
        margin-left: 0;
    }
}