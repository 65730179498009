:root {
  /* Colors: */
  --30e1b9: #30e1b9;
  --8ff8e2: #8ff8e2;
  --625565: #625565;
  --966c6c: #966c6c;
  --eaaded: #eaaded;
  --fbb954: #fbb954;
  --ffffff: #ffffff;
  --fb6b1d: #fb6b1d;
  --fca790: #fca790;
  --c32454: #c32454;
  --831c5d: #831c5d;
  --f68181: #f68181;
  --f04f78: #f04f78;
  --fbff86: #fbff86;
  --4d9be6: #4d9be6;
  --8fd3ff: #8fd3ff;
  --91db69: #91db69;
  --484a77: #484a77;
  --3e3546: #3e3546;
}
@font-face {
  font-family: "kleyn";
  src: url("./assets/fonts/Kleyn-Regular.woff2") format("woff2"),
    url("./assets/fonts/Kleyn-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kleyn-wide";
  src: url("./assets/fonts/Kleyn-Wide.woff2") format("woff2"),
    url("./assets/fonts/Kleyn-Wide.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "digitalix2";
  src: url("./assets/fonts/digitalix2-webfont.woff2") format("woff2"),
    url("./assets/fonts/digitalix2-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "digitalixregular";
  src: url("./assets/fonts/digitalix_regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/digitalix_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "digitalix2", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 9px;
  color: #f68181;
  letter-spacing: 0.3em;
}
/*h1, h2, h3, h4, h5 {*/
/*    font-family: 'apple_regular','digitalix', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;*/
/*}*/

.icons a:hover svg,
.brand-green {
  color: #91db69;
}

#bgLoop {
  position: fixed;
  /* Make video always 100% wide and high */
  min-width: 100%;
  min-height: 100%;
  /* Set width & height to auto prevents video from stretching or squishing */
  width: auto;
  height: auto;
  /* Center video */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  z-index: -1;
}

.home-logo {
  max-width: 65em;
}

.play-logo {
  width: 300px;
  margin: 0 auto;
  display: block;
  height: auto;
}

.btn {
  border-radius: 0;
  min-width: 10em;
  text-transform: uppercase;
  font-size: 1.2em;
}

.btn.btn-primary {
  background: #f68181;
  color: #3e3546;
  border: #3e3546 3px solid;
  outline: 3px solid #f68181;
  text-transform: uppercase;
}

.btn.btn-primary:hover {
  background: #91db69;
  color: #3e3546;
  border: #3e3546 3px solid;
  outline: 3px solid #91db69;
}

.btn.btn-no {
  background: #c32454;
  color: #ffffff;
  outline: 3px solid #c32454;
}

@media only screen and (max-width: 450px) {
  .btn-primary {
    font-size: 0.75rem;
  }
}

.icons a svg {
  color: #f68181;
  font-size: 3em;
}

.icons a:hover svg {
  color: #91db69;
}

h1,
h2,
h3,
.tos {
  color: #8fd3ff;
}
h1 {
  font-size: 3em;
}
h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1em;
}

.container-bordered {
  border: 6px solid #f68181;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}

.container-blurred {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
