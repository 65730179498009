.stats-container, .eon-container {
    margin-top: -6px;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    font-size: 1em;
}

.stats-row, .eon-container {
    margin-bottom: 0.3em;
}
.eon-row{
    width: 100%;
    display: block;
    margin: 0;
    padding-right: 2.5em;
}
.eon-container{
    text-align: center;
    float: right;
}
.stats-left{
    border-right: 0.25em solid #8FD3FF;
}
.stats-title {
    color: #8FD3FF;
}

.stats-value {
    padding-left: 15px;
    color: #FBB954;
}
.eon-value{
    margin-top: 1em;
}